import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from "@reduxjs/toolkit";
import { getFormEntries } from "../../services/apiService";
import { FormEntry } from "../../types/types";
import { RootState } from "../index";

// Async thunk to fetch form entries
export const fetchFormEntries = createAsyncThunk<
  FormEntry[],
  void,
  { rejectValue: string }
>("formEntries/fetchFormEntries", async (_, { rejectWithValue }) => {
  try {
    const data = await getFormEntries();
    return data;
  } catch (error) {
    return rejectWithValue(
      (error as Error).message || "An unknown error occurred."
    );
  }
});

// Explicitly type the initial state
const initialState: {
  allEntries: FormEntry[];
  entries: FormEntry[];
  loading: boolean;
  error: string | null;
} = {
  allEntries: [],
  entries: [],
  loading: false,
  error: null,
};

// Slice for form entries
const formEntriesSlice = createSlice({
  name: "formEntries",
  initialState,
  reducers: {
    resetState: () => ({ ...initialState }), // Use spread to ensure immutability
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormEntries.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchFormEntries.fulfilled,
        (state, action: PayloadAction<FormEntry[]>) => {
          state.loading = false;
          state.allEntries = action.payload;
          state.entries = action.payload.filter(
            (entry) => entry.meta["Paid?"] === "Yes"
          );
        }
      )
      .addCase(fetchFormEntries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

// Selector to find a specific entry by its key
export const selectEntryByKey = (state: RootState, key: string) => {
  return state.formEntries.allEntries.find((entry) => entry.item_key === key);
};

// Memoized selector for entries filtered by key or email (case-insensitive)
export const selectEntriesByItemKeyOrEmail = createSelector(
  [
    (state: RootState) => state.formEntries.allEntries,
    (_: RootState, searchKey: string) => searchKey.toLowerCase(),
  ],
  (entries, searchKey) => {
    if (!searchKey) return [];
    return entries.filter(
      (entry) =>
        entry.item_key.toLowerCase().includes(searchKey) ||
        entry.meta.Email.some((email: string) =>
          email.toLowerCase().includes(searchKey)
        )
    );
  }
);

export const { resetState } = formEntriesSlice.actions;
export default formEntriesSlice.reducer;
