import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

export default function TotalRegistrationRevenue() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Calculate total registration revenue
  const totalRegistrationRevenue = entries.reduce((total, entry) => {
    // Ensure meta exists
    const meta = entry.meta || {};

    // Collect all registrants' data (keys starting with "i" in "Registrants")
    const registrants = meta["Registrants"] || {};
    const registrantKeys = Object.keys(registrants).filter((key) =>
      key.startsWith("i")
    );

    // Add "5yusq3-value" from each registrant's meta to the registration revenue
    const registrantFeeSum = registrantKeys.reduce((sum, key) => {
      const registrantMeta = registrants[key];
      if (registrantMeta && registrantMeta["5yusq3-value"]) {
        const registrantFee = parseFloat(registrantMeta["5yusq3-value"]);
        if (!isNaN(registrantFee)) {
          sum += registrantFee;
        }
      }
      return sum;
    }, 0);

    // Number of registrants and apply registration fees
    const numberOfRegistrants = parseInt(
      meta["Number of Registrants"] || "0",
      10
    );

    // Apply 12% discount if number of registrants is 4 or more
    const entryRevenue =
      numberOfRegistrants >= 4 ? registrantFeeSum * 0.88 : registrantFeeSum;

    // Add to total revenue
    return total + entryRevenue;
  }, 0);

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500">
        Registration Revenue
      </dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        $
        {totalRegistrationRevenue.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </dd>
    </div>
  );
}
