import { useDispatch } from "react-redux";
import { markPaid } from "../../../services/apiService";
import { FormEntry } from "../../../types/types";
import { formatDate } from "../../../utils/utils";
import { Link } from "react-router-dom";
import { AppDispatch } from "../../../store";
import { useNotification } from "../../../context/NotificationContext";
import { fetchFormEntries } from "../../../store/slices/formEntriesSlice";

export default function RegistrantHeader(props: { entry: FormEntry }) {
  const { showNotification } = useNotification();
  const dispatch = useDispatch<AppDispatch>();
  const hasPaid = props.entry.meta["Paid?"] === "Yes" ? true : false;

  const updateEntries = async () => {
    await dispatch(fetchFormEntries());
  };

  const handlePaid = async () => {
    if (props.entry) {
      try {
        await markPaid(props.entry.id, !hasPaid);
        showNotification({
          title: `Successfully Marked as ${hasPaid ? "Unpaid" : "Paid"}`,
          message: `Successfully marked registrant(s) as ${
            hasPaid ? "Unpaid" : "Paid"
          }.`,
          type: "success",
        });
        await updateEntries();
      } catch (error) {
        showNotification({
          title: `Marking ${hasPaid ? "Unpaid" : "Paid"} Failed`,
          message: `Failed to mark registrant(s) as ${
            hasPaid ? "Unpaid" : "Paid"
          }.`,
          type: "error",
        });
        console.error(
          `Failed to mark registrant(s) as ${hasPaid ? "Unpaid" : "Paid"}:`,
          error
        );
      }
    }
  };

  return (
    <section aria-labelledby="general-information-title">
      <div className="mb-8 md:flex md:items-center md:justify-between md:space-x-5">
        <div className="flex items-center space-x-5">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">
              Confirmation #: {props.entry?.item_key}
            </h1>
            <p className="text-sm font-medium text-gray-500">
              Registered on{" "}
              <time dateTime={props.entry?.created_at}>
                {" "}
                {formatDate(props.entry?.created_at)}
              </time>
            </p>
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          <span
            onClick={handlePaid}
            className="cursor-pointer inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-primary shadow-sm hover:bg-gray-200 border-gray-100 border-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
          >
            {hasPaid ? "Mark Unpaid" : "Mark Paid"}
          </span>
          {hasPaid && (
            <Link
              to={`/check-in/${props.entry?.item_key}`}
              className="inline-flex items-center justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
            >
              Check-In
            </Link>
          )}
        </div>
      </div>
    </section>
  );
}
